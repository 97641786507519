import React, {useEffect, useState} from 'react';
import "../../lib/flot/jquery.flot";
import "../../lib/flot/jquery.flot.resize";
import "../../lib/flot/jquery.flot.stack";
import {getSaleRevenueReport} from "../../api/app";
import formatNumber from "../../utils/formatNumber";
const $ = window.$;
const jQuery = window.$;

const SaleRevenue = () => {

    useEffect(() => {
        getSaleRevenueReport().then((response) => {
            if (!response.error) {
                $("#total-revenue").text(`K${formatNumber(response.payload.total_yearly_revenue.this_period_total)}`);
                $("#total-monthly-revenue").text(`K${formatNumber(response.payload.total_monthly_revenue.this_period_total)}`);
                $("#total-weekly-revenue").text(`K${formatNumber(response.payload.total_weekly_revenue.this_period_total)}`);
                $("#total-daily-revenue").text(`K${formatNumber(response.payload.total_daily_revenue.this_period_total)}`);

                $("#yearly-last-period").text(`K${formatNumber(response.payload.total_yearly_revenue.last_period_total)}`);
                $("#monthly-last-period").text(`K${formatNumber(response.payload.total_monthly_revenue.last_period_total)}`);
                $("#weekly-last-period").text(`K${formatNumber(response.payload.total_weekly_revenue.last_period_total)}`);
                $("#daily-last-period").text(`K${formatNumber(response.payload.total_daily_revenue.last_period_total)}`);

                // Update rate differences dynamically
                updateRate("#rate-yearly", response.payload.total_yearly_revenue.rate_difference, "than last year");
                updateRate("#rate-monthly", response.payload.total_monthly_revenue.rate_difference, "than last month");
                updateRate("#rate-weekly", response.payload.total_weekly_revenue.rate_difference, "than last week");
                updateRate("#rate-daily", response.payload.total_daily_revenue.rate_difference, "than yesterday");
            }
        });
    }, []);

    const updateRate = (selector, rate, comparisonText) => {
        const isPositive = rate > 0 || rate === 0; // Treat 0 as positive for the arrow
        const displayRate = rate === 0 ? 100 : Math.abs(rate).toFixed(1); // Display 100% if rate is 0
        const iconName = isPositive ? "arrow-up-outline" : "arrow-down-outline";
        const rateClass = rate === 0 || isPositive ? "tx-success" : "tx-danger"; // Default to success style for 0 or positive rates

        $(selector).html(`
        <span class="tx-medium ${rateClass} d-inline-flex align-items-center">
            ${displayRate}% <ion-icon name="${iconName}"></ion-icon>
        </span> ${comparisonText}
    `);
    };

    return (
        <>
            <div className="col-sm-6 col-lg-3 mg-b-10">
                <div className="card card-body">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Yearly Revenue</h6>
                    <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1" id="total-revenue">K0.00</h3>
                        <p className="tx-11 tx-color-03 mg-b-0" id="rate-yearly"></p>
                    </div>
                    <div className="d-flex mt-2">
                        <p className="tx-12 tx-rubik mg-b-0"><span className="tx-medium text-secondary mg-r-5">LAST YEAR</span> <span id="yearly-last-period">K0.00</span></p>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                <div className="card card-body">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Monthly Revenue</h6>
                    <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1" id="total-monthly-revenue">0.00</h3>
                        <p className="tx-11 tx-color-03 mg-b-0" id="rate-monthly"></p>
                    </div>
                    <div className="d-flex mt-2">
                        <p className="tx-12 tx-rubik mg-b-0"><span className="tx-medium text-secondary mg-r-5">LAST MONTH</span> <span id="monthly-last-period">K0.00</span></p>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                <div className="card card-body">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Weekly Revenue</h6>
                    <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1" id="total-weekly-revenue">K0.00</h3>
                        <p className="tx-11 tx-color-03 mg-b-0" id="rate-weekly"></p>
                    </div>
                    <div className="d-flex mt-2">
                        <p className="tx-12 tx-rubik mg-b-0"><span className="tx-medium text-secondary mg-r-5">LAST WEEK</span> <span id="weekly-last-period">K0.00</span></p>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                <div className="card card-body">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Daily Revenue</h6>
                    <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1" id="total-daily-revenue">K0.00</h3>
                        <p className="tx-11 tx-color-03 mg-b-0" id="rate-daily"></p>
                    </div>
                    <div className="d-flex mt-2">
                        <p className="tx-12 tx-rubik mg-b-0"><span className="tx-medium text-secondary mg-r-5">YESTERDAY</span> <span id="daily-last-period">K0.00</span></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SaleRevenue;
