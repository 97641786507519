import React, {useEffect, useState} from 'react';
import {revenueReport} from "../../api/app";
import formatNumber from "../../utils/formatNumber";
const $ = window.$;

const Revenue = () => {

    const [revenue, setRevenue] = useState([]);

    useEffect(() => {
        revenueReport().then((response) => {
            setRevenue(response.payload.data);
            $("#ovr-total-revenue").text(`K${formatNumber(response.payload.total_sum_waste_paid)}`);
            $("#ovr-total-credit").text(`K${formatNumber(response.payload.total_sum_waste_credit)}`);
        });
    }, []);

    return (
        <div className="col-12 col-lg-8 mg-t-10">
            <div className="card mg-b-10">
                <div
                    className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                    <div>
                        <h6 className="mg-b-5">Your Most Recent Earnings</h6>
                        <p className="tx-13 tx-color-03 mg-b-0">Your sales and referral earnings over the last 30 days</p>
                    </div>
                    <div className="d-flex mg-t-20 mg-sm-t-0">
                        <div className="btn-group flex-fill">
                            <button className="btn btn-white btn-xs active">Lusaka</button>
                            <button className="btn btn-white btn-xs">Calendar</button>
                        </div>
                    </div>
                </div>

                <div className="card-body pd-y-30">
                    <div className="d-sm-flex">
                        <div className="media align-items-center">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-primary tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                                <i data-feather="bar-chart-2"></i>
                            </div>
                            <div className="media-body">
                                <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">Total Revenue</h6>
                                <h4 className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0" id="ovr-total-revenue">K0.00</h4>
                            </div>
                        </div>
                        <div className="media align-items-center mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-5">
                                <i data-feather="bar-chart-2"></i>
                            </div>
                            <div className="media-body">
                                <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">Total Credit</h6>
                                <h4 className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0" id="ovr-total-credit">K0.00</h4>
                            </div>
                        </div>
                        <div className="media align-items-center mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                                <i data-feather="bar-chart-2"></i>
                            </div>
                            <div className="media-body">
                                <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">Dumpsite Tonnes</h6>
                                <h4 className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">0</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive" style={{height: 214}}>
                    <table className="table table-dashboard mg-b-0">
                        <thead>
                        <tr>
                            <th>Garbage Collector</th>
                            <th className="text-right">Customer</th>
                            <th className="text-right">Gross Earnings</th>
                            <th className="text-right">Total Credit</th>
                            <th className="text-right">Dumpsite Tonnes</th>
                        </tr>
                        </thead>
                        <tbody>
                        {revenue.map((item) => {
                            return (
                                <tr>
                                    <td className="tx-color-03 tx-normal">{item.collector_name}</td>
                                    <td className="tx-medium text-right">{item.total_customers}</td>
                                    <td className="text-right tx-medium tx-primary">K{formatNumber(item.total_waste_paid)}</td>
                                    <td className="text-right tx-medium tx-pink">K{formatNumber(item.total_waste_credit)}</td>
                                    <td className="tx-medium text-right">0.0</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Revenue;
