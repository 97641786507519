import React, {useEffect} from 'react';
import {rateReport} from "../../api/app";

const Rating = () => {

    const [rating, setRating] = React.useState([]);

    useEffect(() => {
        rateReport().then((response) => {
            setRating(response.payload.data);
        });
    }, []);

    const renderStars = (rate) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <i
                    key={i}
                    className={`icon ion-md-star lh-0 ${i <= rate ? 'tx-orange' : 'tx-gray-300'}`}
                    style={{ fontSize: '18px' }}
                ></i>
            );
        }
        return stars;
    };

    return (
        <div className="col-12 col-md-4 col-lg-4 mg-t-10">
            <div className="card">
                <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="lh-5 mg-b-5">Overall Rating</h6>
                    <p className="tx-12 tx-color-03 mg-b-0">Measures the quality of the collector efforts or performance.</p>
                </div>

                <div className="card-body pd-0">
                    <div className="list-group list-group-flush tx-13" style={{height: 322}}>
                        {rating.length <= 0 ? <div className="justify-content-center my-auto tx-18 bg-black">
                            <div className="text-center">
                                <span className="tx-color-04"><i data-feather="star" className="wd-60 ht-60"></i></span>
                                <h6 className="lh-5 mg-b-5 mg-t-2">No Rating</h6>
                                <p className="tx-12 tx-color-03 mg-b-0">Ratings will appear shortly after the customer
                                    submits their review.</p>
                            </div>
                        </div> : <div className="list-group list-group-flush tx-13">
                            <div className="list-group list-group-flush tx-13">
                                {rating.map((item) => {
                                    return (
                                        <div
                                            className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                            <div className="tx-rubik tx-color-02">{item.collector}</div>
                                            <div className="d-flex">
                                                <strong className="tx-rubik">{item.averageRating.toFixed(1)}</strong>
                                                <div className="tx-14 mg-l-10">
                                                    {renderStars(item.averageRating)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
                </div>
                </div>
            </div>
            );
            };

            export default Rating;
